import {
  Box,
  Flex,
  ResponsiveValue,
  useBreakpointValue,
} from "@chakra-ui/react";
import { NodeModel } from "@minoru/react-dnd-treeview";
import { IssueValue, ProgramValue } from "core";
import { collection, CollectionReference } from "firebase/firestore";
import React, { useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import { useFirestore, useFirestoreCollection } from "reactfire";
import CardBlock from "../../components/CardBlock";
import { readLocalStorage, writeLocalStorage } from "../../utils/localStorage";
import IssueTree from "./components/IssueTree";
import { TreeData } from "./components/IssueTree/utils/getTreeData";
import { syncTreeData } from "./components/IssueTree/utils/syncTreeData";
import { ProgramsContext } from "./ProgramsContext";

// The index component returns the list of rules, or an outlet to the child component
export default function Issues() {
  const firestore = useFirestore();
  const [treeData, setTreeData] = useState<TreeData>([]);
  const [selectedIssueId, setSelectedIssueId] = useState<undefined | string>(
    readLocalStorage("selectedIssueId")
  );
  const cardFlex = useBreakpointValue({ base: 1, xl: 2 }); // Set flex value for CardBlock
  const outletFlex = useBreakpointValue({ base: 1, xl: 3 }); // Set flex value for Outlet
  const flexDirection = useBreakpointValue({ base: "column", xl: "column" })!; // Set flex direction for Flex
  const selectedIssue = useMemo(
    () =>
      treeData.find(({ id }) => id === selectedIssueId) as
        | (NodeModel<IssueValue> & { id: string })
        | undefined,
    [treeData, selectedIssueId]
  );
  const programCollection = collection(
    firestore,
    "programs"
  ) as CollectionReference<ProgramValue>;
  const { data: programs } = useFirestoreCollection(programCollection);

  useEffect(() => {
    if (treeData.length) {
      const selectedIssueIsInvalid =
        selectedIssueId && !treeData.find(({ id }) => id === selectedIssueId);

      if (!selectedIssueId || selectedIssueIsInvalid)
        setSelectedIssueId(treeData[0].id as string);
    }
  }, [treeData.length]);

  useEffect(() => {
    writeLocalStorage("selectedIssueId", selectedIssueId);
  }, [selectedIssueId]);

  // When the data in firestore changes, write it to the treeData state, and when the treeData
  // changes, write it to firestore
  syncTreeData(treeData, setTreeData);

  return (
    <ProgramsContext.Provider value={{ programs: programs?.docs || [] }}>
      <Flex flexDir={flexDirection as ResponsiveValue<any>}>
        <CardBlock flex={cardFlex} title="Issues" mr={8}>
          <IssueTree
            treeData={treeData}
            setTreeData={setTreeData}
            selectedIssue={selectedIssue}
            setSelectedIssueId={setSelectedIssueId}
          />
        </CardBlock>
        <Box flex={outletFlex}>
          <Outlet />
        </Box>
      </Flex>
    </ProgramsContext.Provider>
  );
}
