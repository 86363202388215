import { Button, Td, Tr } from "@chakra-ui/react";
import { ProgramValue } from "core";
import { deleteDoc } from "firebase/firestore";
import React from "react";
import { Link } from "react-router-dom";
import { Snap } from "../../../utils/firestore";

interface Props {
  program: Snap<ProgramValue>;
  linkTo: string;
  isSelected?: boolean;
}

export const columnNames = ["Program", "Actions"];

export default function ProgramRow({ program, linkTo, isSelected }: Props) {
  const data = program.data();
  const borderColor = "gray.200";

  function handleDeleteButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    deleteDoc(program.ref);
  }

  return (
    <Tr
      bg={isSelected ? "purple.500" : undefined}
      color={isSelected ? "white" : undefined}
    >
      <Td test-id="program-row-issues" borderColor={borderColor}>
        <Link to={linkTo}>{data.title}</Link>
      </Td>
      <Td borderColor={borderColor}>
        <Button mr={2} colorScheme={"red"} onClick={handleDeleteButtonClick}>
          Delete
        </Button>
      </Td>
    </Tr>
  );
}
