import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { NodeModel } from "@minoru/react-dnd-treeview";
import React, { useState } from "react";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (text: string) => void;
  treeData: Array<NodeModel>;
  newIssueParentId?: string | null;
}

// Return a modal component
export default function AddModal({
  isOpen,
  onClose,
  treeData,
  newIssueParentId,
  onSubmit,
}: Props) {
  const [name, setName] = useState("");
  const parent = treeData.find((node) => node.id === newIssueParentId);

  function handleSubmit() {
    onSubmit(name);
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {"Add a new issue" +
            (parent?.text ? " (under " + parent?.text + ")" : "")}
        </ModalHeader>
        <ModalBody>
          <Input
            value={name}
            onKeyUp={(event) => event.key === "Enter" && handleSubmit()}
            onChange={(event) => setName(event.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Close
          </Button>
          <Button colorScheme="green" onClick={handleSubmit}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
