import { ProgramValue } from "core";
import {
  collection,
  CollectionReference,
  doc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFirestore, useFirestoreDoc } from "reactfire";
import { FlashContext } from "../../../../../contexts/FlashProvider";
import ProgramForm from "../../../components/ProgramForm";

export default function EditProgram() {
  const firestore = useFirestore();
  const { programId } = useParams();
  const programsCollection = collection(
    firestore,
    "programs"
  ) as CollectionReference<ProgramValue>;
  const { data: program } = useFirestoreDoc<ProgramValue>(
    doc(programsCollection, programId)
  );

  const { showMessage } = useContext(FlashContext);
  const navigate = useNavigate();

  function handleSubmit(values: Partial<ProgramValue>) {
    updateDoc(program.ref, {
      ...values,
      updatedAt: Timestamp.now(),
    } as any);
    navigate("/issues");
    showMessage({ message: "Program saved", type: "success" });
  }

  const programData = program?.data();
  if (!programData) return null;
  return <ProgramForm program={programData} onSubmit={handleSubmit} />;
}
