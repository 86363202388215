import { Button, Flex } from "@chakra-ui/react";
import { TacticValue } from "core";
import {
  collection,
  CollectionReference,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useMemo, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useFirestore, useFirestoreCollection } from "reactfire";
import TableCard from "../../components/CardBlock/TableBlock";
import { readLocalStorage, writeLocalStorage } from "../../utils/localStorage";
import TacticRow, { columnNames } from "./TacticRow";

// The index component returns the list of rules, or an outlet to the child component
export default function TacticsIndex() {
  let location = useLocation();
  const isIndex = location.pathname === "/tactics";
  if (isIndex) return <Tactics />;
  return <Outlet />;
}

function Tactics() {
  const firestore = useFirestore();
  const [showOnlyRecommendable, setShowOnlyRecommendable] = useState(
    readLocalStorage("showOnlyRecommendableTactics") || false
  );
  const tacticsQuery = useMemo(
    () => getTacticsQuery(),
    [showOnlyRecommendable]
  );
  const { data = { docs: [] } } = useFirestoreCollection(tacticsQuery);

  useEffect(() => {
    writeLocalStorage("showOnlyRecommendableTactics", showOnlyRecommendable);
  }, [showOnlyRecommendable]);

  function getTacticsQuery() {
    const tacticsCollection = collection(
      firestore,
      "tactics"
    ) as CollectionReference<TacticValue>;

    if (!showOnlyRecommendable) return query(tacticsCollection);

    return query(
      tacticsCollection,
      where("isAvailableForRecommendation", "==", showOnlyRecommendable)
    );
  }

  return (
    <TableCard
      columnNames={columnNames}
      title="Tactics"
      rows={data.docs}
      renderRow={(tactic) => <TacticRow tactic={tactic} />}
      action={
        <Flex>
          <Button
            onClick={() => setShowOnlyRecommendable(!showOnlyRecommendable)}
            colorScheme={showOnlyRecommendable ? "blue" : undefined}
            mr={2}
          >
            Show only recommendable tactics
          </Button>
          <Link to="/tactics/new">
            <Button colorScheme={"purple"}>+ Add a tactic</Button>
          </Link>
        </Flex>
      }
    />
  );
}
