import { Button, HStack, Input } from "@chakra-ui/react";
import { TacticValue } from "core";
import { Formik, FormikHelpers } from "formik";
import React from "react";

interface Props {
  editingTacticId: string | null;
  onSubmit: (title: string) => Promise<unknown>;
  initialTitle?: string;
  onCancelEditing: () => void;
}

export default function TacticForm({
  editingTacticId,
  onSubmit,
  initialTitle,
  onCancelEditing,
}: Props) {
  const initialValues: Partial<TacticValue> = { title: initialTitle };

  function onFormSubmit(
    values: typeof initialValues,
    { resetForm }: FormikHelpers<typeof initialValues>
  ) {
    if (!values.title) return;
    onSubmit(values.title).then(() => resetForm());
  }

  return (
    <Formik onSubmit={onFormSubmit} initialValues={initialValues}>
      {({ values, handleSubmit, handleChange }) => (
        <HStack spacing={2}>
          <Input
            placeholder="Add something"
            data-testid="tactic-form-input"
            value={values.title || ""}
            onChange={handleChange("title")}
            name="title"
            onKeyDown={({ key }) => key === "Enter" && handleSubmit()}
          />
          <Button onClick={() => handleSubmit()} type="submit">
            {editingTacticId ? "Save" : "Add"}
          </Button>
          {editingTacticId ? (
            <Button onClick={onCancelEditing}>Cancel</Button>
          ) : null}
        </HStack>
      )}
    </Formik>
  );
}
