import { FormControl, FormErrorMessage, Input } from "@chakra-ui/react";
import { StepsTacticValue } from "core";
import React from "react";
import FormSection from "../../../components/FormSection";
import { SubformProps } from "./props";

type Props = SubformProps<StepsTacticValue>;

export default function StepsSubform({
  values,
  errors,
  isSubmitButtonPressed,
  handleChange,
}: Props) {
  return (
    <FormSection heading="Steps" subheading="Number of steps">
      <FormControl isInvalid={isSubmitButtonPressed && !!errors.steps}>
        <Input
          test-id="tactic-steps"
          value={values.steps}
          onChange={handleChange("steps")}
        />
        <FormErrorMessage>{errors.steps}</FormErrorMessage>
      </FormControl>
    </FormSection>
  );
}
