import { ProgramValue } from "core";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { pickBy } from "lodash";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useFirestore } from "reactfire";
import { FlashContext } from "../../../../../contexts/FlashProvider";
import ProgramForm from "../../../components/ProgramForm";

export default function NewProgram() {
  const firestore = useFirestore();
  const { showMessage } = useContext(FlashContext);
  const navigate = useNavigate();

  // Get the issueId from window query params
  const search = window.location.search;
  const issueId = new URLSearchParams(search).get("issueId");

  function handleSubmit(values: Partial<ProgramValue>) {
    addDoc(collection(firestore, "programs"), {
      ...values,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
    });
    navigate("/issues");
    showMessage({ message: "Program created", type: "success" });
  }

  const program = pickBy(
    {
      forIssueIds: issueId ? [issueId] : undefined,
    },
    (value) => value !== undefined
  );

  return <ProgramForm program={program} onSubmit={handleSubmit} />;
}
