import { tacticSchemas, TacticValue } from "core";
import { Timestamp } from "firebase/firestore";

export function validate(values: Partial<TacticValue>) {
  const schema = tacticSchemas[values.type!];
  try {
    schema.validateSync(
      {
        ...values,
        image: null,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
      },
      { abortEarly: false }
    );
    return {};
  } catch (error: any) {
    // Check if error is an instance of ValidationError
    // Collect all validation errors
    const errors = error.inner.reduce(
      (acc: Record<string, string>, err: any) => {
        // Use a custom logic to handle the case where multiple errors occur for the same field
        if (!acc[err.path!]) {
          acc[err.path!] = err.message;
        } else {
          acc[err.path!] += `, ${err.message}`;
        }
        return acc;
      },
      {}
    );

    return errors;
  }
}
