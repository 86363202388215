import { TaskTacticValue } from "core";
import { SubformProps } from "./props";

type Props = SubformProps<TaskTacticValue>;

export default function TaskSubform({
  values,
  handleChange,
  setFieldValue,
}: Props) {
  return null;
}
