import { Table, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import React from "react";
import CardBlock, { Props as CardBlockProps } from ".";

type Props<T> = CardBlockProps & {
  columnNames: Array<string>;
  rows: Array<T>;
  renderRow: (row: T, index: number) => React.ReactNode;
};

const borderColor = "gray.200";

export default function TableBlock<T>({
  columnNames,
  rows,
  renderRow,
  children,
  ...props
}: Props<T>) {
  return (
    <CardBlock {...props}>
      <Table>
        <Thead>
          <Tr my=".8rem" pl="0px" color="gray.400">
            {columnNames.map((name) => (
              <Th pl="0px" borderColor={borderColor} color="gray.400">
                {name}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>{rows.map(renderRow)}</Tbody>
      </Table>
      {children}
    </CardBlock>
  );
}
