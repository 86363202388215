import { Button, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { NavLink } from "react-router-dom";
import { Route } from "../../router";
import IconBox from "../Icons/IconBox";

interface Props {
  route: Route;
  isActive: boolean;
  variantChange?: string;
}

const activeBg = "purple.600";
const gray = "gray.700";
const sidebarActiveShadow = "0px 7px 11px rgba(0, 0, 0, 0.04)";

export default function SidebarLink({ isActive, variantChange, route }: Props) {
  const { label, path, icon } = route;

  return (
    <NavLink className={"sidebar-link"} to={path!}>
      <Button
        boxSize="initial"
        justifyContent="flex-start"
        alignItems="center"
        boxShadow={sidebarActiveShadow}
        bg={isActive ? activeBg : "gray.200"}
        transition={variantChange}
        mb={{ xl: "8px" }}
        mx={{ xl: "auto" }}
        ps={{ sm: "10px", xl: "16px" }}
        py="12px"
        borderRadius="15px"
        _hover={"none" as any}
        w="100%"
        _active={{
          bg: "inherit",
          transform: "none",
          borderColor: "transparent",
        }}
        _focus={{
          boxShadow: "0px 7px 11px rgba(0, 0, 0, 0.04)",
        }}
      >
        <Flex>
          <IconBox
            bg={isActive ? "white" : activeBg}
            color={isActive ? activeBg : "white"}
            h="30px"
            w="30px"
            me="12px"
            transition={variantChange}
          >
            {icon}
          </IconBox>
          <Text color={isActive ? "white" : gray} my="auto" fontSize="sm">
            {label}
          </Text>
        </Flex>
      </Button>
    </NavLink>
  );
}
