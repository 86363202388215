import { TacticValue } from "core";

export const types: NonNullable<TacticValue["type"]>[] = [
  "task",
  "breathe",
  "question",
  "steps",
  "audio",
  "video",
  "emotions",
  "measure-slider",
  "measure-time",
  "measure-counter",
];
