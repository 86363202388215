import { gameplanSchema, logSchema } from "core";
import { DocumentData } from "firebase/firestore";

export const validators: Record<
  string,
  typeof logSchema | typeof gameplanSchema
> = {
  logs: logSchema,
  gameplans: gameplanSchema,
};

export function getInvalidDocs(
  data: Array<DocumentData & { id: string }>,
  key: keyof typeof validators
) {
  const validator = validators[key];
  return data
    .map((doc) => {
      try {
        validator.validateSync(doc, { abortEarly: false });
        return { doc, errors: [] }; // No errors if validation is successful
      } catch (validationError: any) {
        const errors = validationError.inner?.length
          ? validationError.inner
          : [validationError];
        return { doc, errors };
      }
    })
    .filter((record) => record.errors?.length > 0);
}
