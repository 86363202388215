import {
  AddIcon,
  ChevronRightIcon,
  DeleteIcon,
  EditIcon,
  StarIcon,
} from "@chakra-ui/icons";
import { Flex, IconButton, Input, Text, useDisclosure } from "@chakra-ui/react";
import { NodeModel, useDragOver } from "@minoru/react-dnd-treeview";
import { IssueValue } from "core";
import React, { UIEvent, useEffect, useRef, useState } from "react";
import theme from "../../../../../theme/theme";
import styles from "./styles.module.css";

const pluralize = require("pluralize");

interface Props {
  node: NodeModel<IssueValue> & { hasChildren: boolean };
  depth: number;
  isOpen: boolean;
  isSelected?: boolean;
  onToggle: () => void;
  onDelete: () => void;
  onSelect: () => void;
  onAddClick: () => void;
  onFeatureClick: () => void;
  onFormSubmit: (text: string) => void;
  onActivateEditMode: (isEditing: boolean) => void;
  hideActions: boolean;
}

export const CustomNode = ({
  node,
  depth,
  isOpen,
  onToggle,
  onDelete,
  onSelect,
  onAddClick,
  onFeatureClick,
  isSelected,
  onFormSubmit,
  onActivateEditMode,
  hideActions,
}: Props) => {
  const [hover, setHover] = useState(false);
  const id = node.id as string;
  const indent = depth * 24;
  const [text, setText] = useState(node.text);
  const {
    isOpen: isEditing,
    onOpen: onOpenEdit,
    onClose: onEditClose,
  } = useDisclosure();
  const inputRef = useRef<HTMLInputElement>(null);
  const dragOverProps = useDragOver(id, isOpen, onToggle);
  const purple = theme.colors.purple[500];

  function handleToggle(e: any) {
    e.stopPropagation();
    onToggle();
  }

  function handleSubmit() {
    onFormSubmit(text);
    onEditClose();
  }

  function handleKeyUp(e: any) {
    if (e.key === "Enter") handleSubmit();
    if (e.key === "Escape") onEditClose();
  }

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    }
    onActivateEditMode(isEditing);
  }, [isEditing]);

  return (
    <Flex
      className={`tree-node ${styles.root}`}
      style={{
        paddingInlineStart: indent,
        backgroundColor: isSelected ? purple : "",
        color: isSelected ? "white" : "",
        opacity: node.data?.profileCount ? 1 : 0.5,
      }}
      h={isEditing ? "42px!important" : undefined}
      {...dragOverProps}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onSelect}
    >
      <div
        className={`${styles.expandIconWrapper} ${isOpen ? styles.isOpen : ""}`}
      >
        {node.hasChildren && (
          <div onClick={handleToggle}>
            <ChevronRightIcon h={6} w={6} />
          </div>
        )}
      </div>
      <Flex pr={8}>
        {isEditing ? (
          <Input
            value={text}
            onChange={(event) => setText(event.target.value)}
            onKeyUp={handleKeyUp}
            ref={inputRef}
          />
        ) : (
          <Flex>
            <Text>{node.text}</Text>

            <Text color={isSelected ? "white" : "gray"} ml={2}>
              ({pluralize("person", node.data?.profileCount || 0, true)})
            </Text>
          </Flex>
        )}
      </Flex>
      {hover && !hideActions && (
        <Flex>
          <IconButton
            aria-label="Delete"
            className={styles.actionButton}
            size="small"
            onClick={withoutPropatation(onDelete)}
          >
            <DeleteIcon
              color={isSelected ? "black" : undefined}
              fontSize="small"
            />
          </IconButton>

          <IconButton
            aria-label="Edit"
            className={styles.actionButton}
            size="small"
            onClick={withoutPropatation(onOpenEdit)}
          >
            <EditIcon
              color={isSelected ? "black" : undefined}
              fontSize="small"
            />
          </IconButton>

          <IconButton
            aria-label="Add child"
            className={styles.actionButton}
            size="small"
            onClick={withoutPropatation(onAddClick)}
          >
            <AddIcon
              color={isSelected ? "black" : undefined}
              fontSize="small"
            />
          </IconButton>

          <IconButton
            aria-label="Feature"
            className={styles.actionButton}
            size="small"
            onClick={withoutPropatation(onFeatureClick)}
          >
            <StarIcon
              color={node.data?.isFeatured ? "red" : "black"}
              fontSize="small"
            />
          </IconButton>
        </Flex>
      )}
      {!hover && node.data?.isFeatured ? (
        <StarIcon color="white" fontSize="small" />
      ) : null}
    </Flex>
  );
};

function withoutPropatation(handler: () => void) {
  return function (event: UIEvent) {
    event.stopPropagation();
    handler();
  };
}
