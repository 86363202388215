import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
} from "@chakra-ui/react";
import { BreatheTacticValue } from "core";
import React from "react";
import FormSection from "../../../components/FormSection";
import { SubformProps } from "./props";

type Props = SubformProps<BreatheTacticValue>;

export default function BreatheSubform({
  values,
  errors,
  handleChange,
  isSubmitButtonPressed,
  setFieldValue,
}: Props) {
  return (
    <FormSection>
      <Flex>
        <Box flex={1} mr={2}>
          <FormControl isInvalid={isSubmitButtonPressed && !!errors.title}>
            <Heading size="xs">In for (seconds)</Heading>
            <Input
              type="number"
              value={values.inFor}
              onChange={handleChange("inFor")}
              mr={2}
            ></Input>
            <FormErrorMessage>{errors.inFor}</FormErrorMessage>
          </FormControl>
        </Box>
        <Box flex={1} mr={2}>
          <FormControl isInvalid={isSubmitButtonPressed && !!errors.holdFor}>
            <Heading size="xs">Hold for (seconds)</Heading>
            <Input
              type="number"
              value={values.holdFor}
              onChange={handleChange("holdFor")}
              mr={2}
            ></Input>
            <FormErrorMessage>{errors.holdFor}</FormErrorMessage>
          </FormControl>
        </Box>
        <Box flex={1} mr={2}>
          <FormControl isInvalid={isSubmitButtonPressed && !!errors.outFor}>
            <Heading size="xs">Out for (seconds)</Heading>
            <Input
              type="number"
              value={values.outFor}
              onChange={handleChange("outFor")}
              mr={2}
            ></Input>
            <FormErrorMessage>{errors.outFor}</FormErrorMessage>
          </FormControl>
        </Box>
      </Flex>
    </FormSection>
  );
}
