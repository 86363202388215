import { IssueValue } from "core";
import { Snap } from "../../../../../utils/firestore";

export type TreeData = ReturnType<typeof getTreeData>;
export function getTreeData(issues: Array<Snap<IssueValue>>) {
  const parentSet = new Set();

  // Populate the Set with parentIds
  issues.forEach((issue) => {
    const parentId = issue.data().parentId;
    if (parentId) parentSet.add(parentId);
  });

  // Map over the issues to create the tree data, using the Set to check for children
  return issues.map((issue) => ({
    text: issue.data().name,
    parent: issue.data().parentId || 0,
    id: issue.id,
    hasChildren: parentSet.has(issue.id),
    data: issue.data(),
    droppable: true,
  }));
}
