import { Divider, Heading, Text } from "@chakra-ui/react";
import { TacticValue } from "core";
import React, { useState } from "react";
import Card from "../../../../components/Card/Card";
import Tactic from "./Tactic";
import TacticForm from "./TacticForm";

interface Props {
  title: string;
  tacticIds: string[];
  suggestedTacticIds: string[];
  tacticsById: Record<string, TacticValue>;
}

export default function TacticsList({
  title,
  tacticIds,
  suggestedTacticIds,
  tacticsById,
}: Props) {
  const [editingTacticId, setEditingTacticId] = useState<string | null>(null);

  async function handleAddSuggestion(title: string) {
    // const newTactic = await addDoc(
    //   collection(firestore, "tactics") as CollectionReference<TaskTactic>,
    //   {
    //     title,
    //     type: "task",
    //     createdAt: Timestamp.now(),
    //     updatedAt: Timestamp.now(),
    //     backgroundColor: sample(tacticColors)!,
    //     ordinal: 0,
    //   }
    // );
    // updateDoc(gameplanDoc, {
    //   suggestedTacticIds: arrayUnion(newTactic.id),
    // });
  }

  function handleUpdateTactic(title: string) {
    return Promise.resolve();
    // if (!editingTacticId)
    // const tacticDoc = doc(firestore, "tactics", editingTacticId);
    // return updateDoc(tacticDoc, { title });
  }

  function handleRemoveTacticSuggestion(id: string) {
    if (confirm("Remove this tactic from the user's gameplan?")) {
      // updateDoc(gameplanDoc, {
      //   suggestedTacticIds: arrayRemove(id),
      // });
    }
  }

  function handleRemoveTactic(id: string) {
    if (confirm("Remove this tactic from the user's gameplan?")) {
      // updateDoc(gameplanDoc, {
      //   tacticIds: arrayRemove(id),
      // });
    }
  }

  return (
    <Card data-testid="gameplan-card" p="16px" mb="24px">
      <Heading mb={4} size="sm">
        {title}
      </Heading>
      <Text mb={2} fontSize={13} fontStyle="italic">
        Gameplan
      </Text>
      {tacticIds.map((tacticId) =>
        tacticsById[tacticId] ? (
          <Tactic
            tactic={tacticsById[tacticId]}
            onRemoveButtonClick={() => handleRemoveTactic(tacticId)}
          />
        ) : null
      )}
      {tacticIds.length === 0 ? (
        <Text fontSize={12} color="gray.300">
          (Nothing here yet)
        </Text>
      ) : null}
      <Divider my={4} />
      <Text mb={2} fontSize={13} fontStyle="italic">
        Suggestions
      </Text>
      {suggestedTacticIds.map((tacticId) =>
        tacticsById[tacticId] ? (
          <Tactic
            tactic={tacticsById[tacticId]}
            onEditButtonClick={() => setEditingTacticId(tacticId)}
            onRemoveButtonClick={() => handleRemoveTacticSuggestion(tacticId)}
          />
        ) : null
      )}
      <TacticForm
        key={editingTacticId}
        editingTacticId={editingTacticId}
        initialTitle={
          editingTacticId ? tacticsById?.[editingTacticId]?.title : undefined
        }
        onCancelEditing={() => setEditingTacticId(null)}
        onSubmit={editingTacticId ? handleUpdateTactic : handleAddSuggestion}
      />
    </Card>
  );
}
