import { ProfileValue } from "core";
import { collection, CollectionReference } from "firebase/firestore";
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import TableCard from "../../components/CardBlock/TableBlock";
import { WithId } from "../../types/utils";
import UserRow, { columnNames } from "./UserRow";

// The index component returns the list of users, or an outlet to the child (user) component
export default function UsersIndex() {
  let location = useLocation();
  const isIndex = location.pathname === "/users";
  if (isIndex) return <Users />;
  return <Outlet />;
}

function Users() {
  const firestore = useFirestore();
  const { data = [] } = useFirestoreCollectionData(
    collection(firestore, "profiles") as CollectionReference<
      WithId<ProfileValue>
    >,
    { idField: "id" }
  );

  return (
    <TableCard
      rows={data}
      renderRow={(profile) => <UserRow profile={profile} />}
      columnNames={columnNames}
      title="Users"
    />
  );
}
