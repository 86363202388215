import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
} from "@chakra-ui/react";
import {
  IssueValue,
  makeValidationFn,
  programSchema,
  ProgramValue,
  TacticValue,
} from "core";
import {
  collection,
  CollectionReference,
  documentId,
  getDocs,
  Query,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { Formik } from "formik";
import { isEmpty, keyBy, mapValues, sumBy } from "lodash";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useAuth, useFirestore } from "reactfire";
import Errors from "../../../../components/Errors";
import FormSection from "../../../../components/FormSection";
import { useSelectOptions } from "../../../../utils/selectOptions";
import { StrategySection } from "./StrategySection";

interface Props {
  program: Partial<ProgramValue>;
  onSubmit: (program: Partial<ProgramValue>) => void;
}

const validate = makeValidationFn(programSchema);

export default function ProgramForm({ program, onSubmit }: Props) {
  const { currentUser } = useAuth();
  const firestore = useFirestore();
  const [applicablePeopleCount, setApplicablePeopleCount] = useState<
    number | undefined
  >(0);
  const [isSubmitButtonPressed, setSubmitButtonPressed] = useState(false);
  const { docs: tactics, options: tacticOptions } = useSelectOptions(
    query(
      collection(firestore, "tactics") as CollectionReference<TacticValue>,
      where("isAvailableForRecommendation", "==", true)
    ),
    "title"
  );
  const { options: issueOptions } = useSelectOptions(
    collection(firestore, "issues") as CollectionReference<IssueValue>,
    "name"
  );

  async function fetchApplicablePeopleCount(values: Partial<ProgramValue>) {
    if (isEmpty(values.forIssueIds)) return 0;

    const { docs } = await getDocs(
      query(
        collection(firestore, "issues"),
        where(documentId(), "in", values.forIssueIds)
      ) as Query<IssueValue>
    );

    return sumBy(docs, (doc) => doc.data().profileCount || 0);
  }

  // function getMomentFromTime(values: Partial<ProgramValue>) {
  //   const gameplan = getGameplan(values);
  //   if (!gameplan) return moment();

  //   return moment()
  //     .hours(gameplan.hour || 0)
  //     .minutes(gameplan.minute || 0)
  //     .seconds(0)
  //     .milliseconds(0);
  // }

  function getTacticsById(tacticIds: Array<string>) {
    const selectedTactics = tactics.filter((doc) => tacticIds.includes(doc.id));
    return mapValues(keyBy(selectedTactics, "id"), (doc) => doc.data());
  }

  function extended(values: Partial<ProgramValue>) {
    const allTacticIds = [
      ...(values.main?.tacticIds || []),
      ...(values.success?.tacticIds || []),
      ...(values.setback?.tacticIds || []),
    ];

    return {
      ...values,
      tacticsById: getTacticsById(allTacticIds),
      uid: currentUser!.uid,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
    };
  }

  function validateExtended(values: Partial<ProgramValue>) {
    return validate(extended(values));
  }

  return (
    <Formik
      validate={validateExtended}
      initialValues={program}
      onSubmit={onSubmit}
    >
      {({ values, errors, handleChange, setFieldValue, handleSubmit }) => {
        useEffect(() => {
          setApplicablePeopleCount(undefined);
          fetchApplicablePeopleCount(values)?.then(setApplicablePeopleCount);
        }, [values.forIssueIds]);

        return (
          <Box mb={8}>
            <FormSection heading="Basics">
              <FormControl isInvalid={!!errors["forIssueIds"]}>
                <Flex align="center" minWidth="100%" flexWrap="nowrap">
                  <Box flex={1} mr={4}>
                    <FormControl isInvalid={!!errors["forIssueIds"]}>
                      <Heading size="xs" mb={1}>
                        Title
                      </Heading>
                      <Input
                        value={values.title}
                        onChange={handleChange("title")}
                      ></Input>

                      <FormErrorMessage>
                        {errors["forIssueIds"]}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                  <Box flex={1} mr={4}>
                    <Heading size="xs" mb={1}>
                      For issues
                    </Heading>
                    <Select
                      inputId="recommendation-for-issue-ids"
                      isMulti
                      value={issueOptions.filter(({ value }) =>
                        values.forIssueIds?.includes(value)
                      )}
                      onChange={(selected) =>
                        setFieldValue(
                          "forIssueIds",
                          selected.map(({ value }) => value)
                        )
                      }
                      options={issueOptions}
                    ></Select>
                  </Box>
                  <Box flex={1}>
                    <Heading size="xs" mb={1}>
                      Applicable people
                    </Heading>
                    <Heading size="lg">
                      {applicablePeopleCount === undefined
                        ? "..."
                        : applicablePeopleCount}
                    </Heading>
                  </Box>
                </Flex>
                <FormErrorMessage>{errors["forIssueIds"]}</FormErrorMessage>
              </FormControl>
            </FormSection>

            <FormSection heading="Tactics">
              <Flex experimental_spaceX={4}>
                <StrategySection
                  title="Impulse moments"
                  tacticIds={values.main?.tacticIds || []}
                  tacticOptions={tacticOptions}
                  onChange={(tacticIds) => {
                    setFieldValue("main", {
                      tacticIds,
                    });
                  }}
                />
                <StrategySection
                  title="After a success"
                  tacticIds={values.success?.tacticIds || []}
                  tacticOptions={tacticOptions}
                  onChange={(tacticIds) =>
                    setFieldValue("success", {
                      tacticIds,
                    })
                  }
                />
                <StrategySection
                  title="After a setback"
                  tacticIds={values.setback?.tacticIds || []}
                  tacticOptions={tacticOptions}
                  onChange={(tacticIds) =>
                    setFieldValue("setback", {
                      tacticIds,
                    })
                  }
                />
              </Flex>
            </FormSection>

            <Box backgroundColor="white" borderRadius={10} p={10}>
              {isSubmitButtonPressed ? <Errors errors={errors} /> : null}
              <Button
                test-id="recommendation-save"
                colorScheme="blue"
                type="submit"
                onClick={() => {
                  setSubmitButtonPressed(true);
                  handleSubmit();
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        );
      }}
    </Formik>
  );
}
