import { StarIcon } from "@chakra-ui/icons";
import React, { ReactNode } from "react";
import { createBrowserRouter, RouteObject } from "react-router-dom";
import {
  ClockIcon,
  DocumentIcon,
  HomeIcon,
  PersonIcon,
  RocketIcon,
} from "./components/Icons/Icons";
import AdminLayout from "./layouts/Admin";
import Reports from "./pages/Dashboard/Reports";
import InvalidRecordsScreen from "./pages/Invalid";
import IssuesIndex from "./pages/Issues";
import IssuePage from "./pages/Issues/pages/IssuePage";
import EditProgram from "./pages/Issues/pages/IssuePage/pages/EditProgram";
import NewProgram from "./pages/Issues/pages/IssuePage/pages/NewProgram";
import { Mail, Mails } from "./pages/Mail";
import SignIn from "./pages/SignIn";
import SignOut from "./pages/SignOut";
import Tactics from "./pages/Tactics";
import EditTacticScreen from "./pages/Tactics/EditTactic";
import NewTacticScreen from "./pages/Tactics/NewTactic";
import Users from "./pages/Users";
import UserScreen from "./pages/Users/User";

export type Route = Omit<RouteObject, "children"> & {
  label?: string;
  icon?: ReactNode;
  requiredClaims?: Array<string>;
  showInSidebar?: boolean;
  children?: Array<Route>;
};

export type Routes = typeof routes;

export const routes: Array<Route> = [
  {
    path: "/",
    label: "Dashboard",
    element: <AdminLayout />,
    icon: <HomeIcon />,
    showInSidebar: false,
    requiredClaims: ["admin"],
    children: [
      { path: "/", element: <Reports /> },
      {
        path: "/issues",
        label: "Issues",
        showInSidebar: true,
        icon: <StarIcon />,
        element: <IssuesIndex />,
        children: [
          {
            path: "/issues/:issueId",
            element: <IssuePage />,
            children: [
              {
                path: "/issues/:issueId/programs/new",
                element: <NewProgram />,
                children: [],
              },
              {
                path: "/issues/:issueId/programs/:programId",
                element: <EditProgram />,
                children: [],
              },
            ],
          },
        ],
      },
      {
        path: "/mail",
        label: "Mail",
        showInSidebar: false,
        element: <Mails />,
        children: [
          {
            path: "/mail/:id",
            label: "Mail",
            icon: <DocumentIcon />,
            element: <Mail />,
          },
        ],
      },
      {
        path: "/tactics",
        label: "Tactics",
        showInSidebar: true,
        icon: <RocketIcon />,
        element: <Tactics />,
        children: [
          {
            path: "/tactics/new",
            element: <NewTacticScreen />,
          },
          {
            path: "/tactics/:id/edit",
            element: <EditTacticScreen />,
          },
        ],
      },
      {
        path: "/users",
        label: "Users",
        showInSidebar: true,
        icon: <PersonIcon />,
        element: <Users />,
        children: [
          {
            path: "/users/:id",
            label: "Users",
            element: <UserScreen />,
            children: [],
          },
        ],
      },
      {
        path: "/invalid",
        element: <InvalidRecordsScreen />,
      },
    ],
  },
  {
    path: "/signin",
    element: <SignIn />,
  },
  {
    path: "/signout",
    label: "Sign Out",
    icon: <ClockIcon />,
    element: <SignOut />,
  },
];
const router = createBrowserRouter(routes as Array<RouteObject>);

export default router;
