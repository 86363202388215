import {
  DndProvider,
  getBackendOptions,
  MultiBackend,
  NodeModel,
  Tree,
} from "@minoru/react-dnd-treeview";
import { IssueValue } from "core";
import {
  collection,
  CollectionReference,
  deleteDoc,
  doc,
} from "firebase/firestore";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFirestore } from "reactfire";
import AddModal from "../../AddModal";
import { CustomDragPreview } from "./CustomDragPreview";
import { CustomNode } from "./IssueNode";
import styles from "./styles.module.css";
import { createIssue } from "./utils/createIssue";
import { TreeData } from "./utils/getTreeData";

interface Props {
  treeData: TreeData;
  setTreeData: (treeData: TreeData) => void;
  selectedIssue?: NodeModel<IssueValue>;
  setSelectedIssueId: (id: string) => void;
}

export default function IssueTree({
  treeData,
  setTreeData,
  selectedIssue,
  setSelectedIssueId,
}: Props) {
  const firestore = useFirestore();
  const issuesCollection = collection(
    firestore,
    "issues"
  ) as CollectionReference<IssueValue>;
  const [isEditingAnyIssue, setIsEditingAnyIssue] = useState(false);
  // We use this state variable to control the visibility of the modal, and to set the parentId of
  // the new issue after it's created, if applicable. When set to null, we want to open the model,
  // but not set a parentId.
  const [newIssueParentId, setNewIssueParentId] = useState<
    string | undefined | null
  >();
  const isOpen = typeof newIssueParentId !== "undefined";
  const onClose = () => setNewIssueParentId(undefined);
  const handleDelete = (id: string) => deleteDoc(doc(issuesCollection, id));

  const handleSubmit = (name: string) => {
    createIssue(issuesCollection, { name, parentId: newIssueParentId });
    setNewIssueParentId(undefined);
  };

  // If the issue is featured, we un-feature it, and vice versa
  function handleFeatureClick(id: string) {
    setTreeData(
      treeData.map((node) =>
        node.id === id
          ? {
              ...node,
              data: { ...node.data, isFeatured: !node.data?.isFeatured } as any,
            }
          : node
      )
    );
  }

  function handleEditNode(id: string | number, text: any) {
    setTreeData(
      treeData.map((node) => (node.id === id ? { ...node, text } : node))
    );
  }

  const handleDrop = (newTree: any) => setTreeData(newTree);

  return (
    <>
      <DndProvider backend={MultiBackend} options={getBackendOptions()}>
        {treeData.length ? (
          <Tree
            tree={treeData}
            rootId={"0"}
            initialOpen
            render={(node, options) => (
              <Link to={`/issues/${node.id}`}>
                <CustomNode
                  node={node as any}
                  {...options}
                  onSelect={() => setSelectedIssueId(node.id as string)}
                  isSelected={selectedIssue?.id === node.id}
                  onDelete={() => handleDelete(node.id as string)}
                  onFeatureClick={() => handleFeatureClick(node.id as string)}
                  onFormSubmit={(text: string) => handleEditNode(node.id, text)}
                  onActivateEditMode={setIsEditingAnyIssue}
                  onAddClick={() => setNewIssueParentId(node.id as string)}
                  hideActions={isEditingAnyIssue}
                />
              </Link>
            )}
            dragPreviewRender={(monitorProps) => (
              <CustomDragPreview monitorProps={monitorProps} />
            )}
            onDrop={handleDrop}
            classes={{
              root: styles.treeRoot,
              draggingSource: styles.draggingSource,
              dropTarget: styles.dropTarget,
            }}
          />
        ) : null}
      </DndProvider>
      {isOpen ? (
        <AddModal
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={handleSubmit}
          treeData={treeData}
          newIssueParentId={newIssueParentId}
        />
      ) : null}
    </>
  );
}
