import { DocumentData, Query } from "firebase/firestore";
import { useMemo } from "react";
import { useFirestoreCollection } from "reactfire";

export function useSelectOptions<T extends DocumentData>(
  query: Query<T>,
  titleField: keyof T
) {
  const { data: { docs } = { docs: [] } } = useFirestoreCollection(query, {
    idField: "id",
  });
  const options = useMemo(
    () =>
      docs.map((doc) => ({
        value: doc.id,
        label: doc.data()?.[titleField] as string,
      })),
    [docs]
  );
  return { docs, options };
}
