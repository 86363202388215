import { Heading, Select } from "@chakra-ui/react";
import {
  collection,
  CollectionReference,
  limit,
  query,
} from "firebase/firestore";
import humanizeString from "humanize-string";
import { keys } from "lodash";
import React, { useMemo } from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import TableBlock from "../../components/CardBlock/TableBlock";
import { WithId } from "../../types/utils";
import DocumentRow, { columnNames } from "./DocumentRow";
import { getInvalidDocs, validators } from "./getInvalidDocs";

const pluralize = require("pluralize");

export default function InvalidRecordsScreen() {
  const [collectionName, setCollectionName] = React.useState(
    keys(validators)[0]
  );
  const firestore = useFirestore();
  const { data = [] } = useFirestoreCollectionData(
    query(
      collection(firestore, collectionName) as CollectionReference<
        WithId<unknown>
      >,
      limit(1000)
    ),
    { idField: "id" }
  );

  const invalidRecords = useMemo(
    () => getInvalidDocs(data, collectionName),
    [data, collectionName]
  );

  return (
    <div>
      <Heading>
        ({pluralize("Record", data.length, true)},{" "}
        {pluralize("Invalid record", invalidRecords.length, true)})
      </Heading>
      <Select onChange={(e) => setCollectionName(e.target.value)}>
        {keys(validators).map((key) => (
          <option value={key}>{humanizeString(key)}</option>
        ))}
      </Select>

      <TableBlock
        rows={invalidRecords}
        renderRow={({ doc, errors }) => (
          <DocumentRow
            doc={doc}
            linkTo={`/${collectionName}/${doc.id}`}
            errors={errors}
          />
        )}
        columnNames={columnNames}
        title="Documents"
      ></TableBlock>
    </div>
  );
}
