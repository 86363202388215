export function writeLocalStorage(key: string, value: unknown) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function readLocalStorage(key: string) {
  try {
    return JSON.parse(localStorage.getItem(key)!);
  } catch {
    return null;
  }
}
