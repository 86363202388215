import { TacticValue } from "core";
import React, { ReactElement } from "react";
import BreatheSubform from "./Breathe";
import { SubformProps } from "./props";
import StepsSubform from "./Steps";
import TaskSubform from "./Task";

const defaultView = () => <div></div>;
defaultView.notSupported = true;

export const subforms: Record<
  NonNullable<TacticValue["type"]> | "default",
  ((props: SubformProps<any>) => ReactElement | null) & {
    notSupported?: boolean;
  }
> = {
  task: TaskSubform,
  breathe: BreatheSubform,
  question: () => <div></div>,
  steps: StepsSubform,
  default: defaultView,
  phone: defaultView,
  audio: defaultView,
  video: defaultView,
  emotions: defaultView,
  folder: defaultView,
  "urge-surfing": defaultView,
  "measure-slider": defaultView,
  "measure-time": defaultView,
  "measure-counter": defaultView,
};
