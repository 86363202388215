import { ImageType } from "core";
import { getDownloadURL, ref } from "firebase/storage";
import { useEffect, useState } from "react";
import { useStorage } from "reactfire";

export function useImageUri(image?: ImageType | null) {
  const storagePath = image?.storagePath;
  const [uri, setUri] = useState<string | undefined>(image?.uri || undefined);

  const storage = useStorage();
  useEffect(() => {
    if (!storagePath) return;
    getDownloadURL(ref(storage, storagePath)).then(setUri);
  }, [storagePath]);

  return uri;
}
