import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
} from "@chakra-ui/react";
import { sampleImages, tacticColors, TacticValue } from "core";
import { Formik } from "formik";
import humanizeString from "humanize-string";
import { isEmpty, sample } from "lodash";
import React, { useState } from "react";
import Errors from "../../components/Errors";
import FormSection from "../../components/FormSection";
import Image from "../../components/Image";
import { subforms } from "./subforms";
import { types } from "./types";
import { validate } from "./validate";

interface Props {
  tactic: Partial<TacticValue>;
  isSubmitting: boolean;
  onSubmit: (values: Partial<TacticValue>) => void;
}

export default function TacticForm({ tactic, isSubmitting, onSubmit }: Props) {
  const [isSubmitButtonPressed, setSubmitButtonPressed] = useState(false);

  function extended(values: Partial<TacticValue>) {
    return {
      ...values,
      backgroundColor: values.backgroundColor || sample(tacticColors),
    };
  }

  function validateExtended(values: Partial<TacticValue>) {
    validate(extended(values));
  }

  function onSubmitExtended(values: Partial<TacticValue>) {
    onSubmit(extended(values));
  }

  return (
    <Formik
      validate={validateExtended}
      initialValues={tactic}
      onSubmit={onSubmitExtended}
    >
      {({ values, errors, handleChange, setFieldValue, handleSubmit }) => {
        const Subform = subforms[values.type || "default"];

        return (
          <div>
            <FormSection
              heading="Type"
              subheading="Grayed-out options aren't currently supported in this web view"
            >
              {types.map((type) => (
                <Button
                  test-id={`tactic-type-${type}`}
                  colorScheme={values.type === type ? "purple" : undefined}
                  isDisabled={subforms[type].notSupported}
                  onClick={() => setFieldValue("type", type)}
                  mb={2}
                  mr={2}
                >
                  {humanizeString(type)}
                </Button>
              ))}
            </FormSection>
            <FormSection heading="Title">
              <FormControl isInvalid={isSubmitButtonPressed && !!errors.title}>
                <Input
                  test-id="tactic-title"
                  value={values.title}
                  onChange={handleChange("title")}
                />
                <FormErrorMessage>{errors.title}</FormErrorMessage>
              </FormControl>
            </FormSection>
            <FormSection heading="Background">
              <FormControl isInvalid={isSubmitButtonPressed && !!errors.image}>
                <Flex mb={4}>
                  <Button
                    mr={2}
                    onClick={() =>
                      setFieldValue("image", {
                        storagePath: sample(sampleImages),
                      })
                    }
                  >
                    Shuffle image
                  </Button>
                  <Button
                    onClick={() => {
                      setFieldValue("image", null);
                      setFieldValue("backgroundColor", sample(tacticColors));
                    }}
                  >
                    Shuffle color
                  </Button>
                </Flex>
                <FormErrorMessage>{errors.image}</FormErrorMessage>
              </FormControl>
              {values.image ? (
                <Image image={values.image} height={400} width={300} />
              ) : (
                <Box height={400} width={300} bg={values.backgroundColor!} />
              )}
            </FormSection>
            <FormSection heading="Recommendations">
              <FormControl isInvalid={isSubmitButtonPressed && !!errors.title}>
                <Checkbox
                  isChecked={!!values.isAvailableForRecommendation}
                  onChange={handleChange("isAvailableForRecommendation")}
                >
                  Make available to use in recommendations
                </Checkbox>
                <FormErrorMessage>{errors.title}</FormErrorMessage>
              </FormControl>
            </FormSection>
            <Subform
              isSubmitButtonPressed={isSubmitButtonPressed}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              errors={errors}
              values={values}
            />
            <FormSection>
              {isSubmitButtonPressed && !isEmpty(errors) ? (
                <Errors errors={errors} />
              ) : null}
              <Button
                isDisabled={isSubmitting}
                test-id="tactic-save-button"
                onClick={() => {
                  setSubmitButtonPressed(true);
                  handleSubmit();
                }}
              >
                Save
              </Button>
            </FormSection>
          </div>
        );
      }}
    </Formik>
  );
}
