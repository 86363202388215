import { Box, Heading, Text } from "@chakra-ui/react";
import React from "react";

type Props = {
  children?: React.ReactNode;
  heading?: string;
  subheading?: string;
  flex?: number;
};

export default function FormSection({
  children,
  heading,
  subheading,
  ...rest
}: Props) {
  return (
    <Box
      test-id="form-section"
      mb={4}
      backgroundColor="white"
      borderRadius={10}
      p={10}
      {...rest}
    >
      {heading ? (
        <Heading mb={4} size="md">
          {heading}
        </Heading>
      ) : null}
      {subheading ? (
        <Text mt={-3} mb={4}>
          {subheading}
        </Text>
      ) : null}
      {children}
    </Box>
  );
}
