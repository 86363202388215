import { IssueValue } from "core";
import { addDoc, CollectionReference, Timestamp } from "firebase/firestore";
import { pickBy } from "lodash";

export function createIssue(
  issuesCollection: CollectionReference,
  data: { name: string; parentId?: string | null }
) {
  return addDoc(
    issuesCollection,
    pickBy({
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
      ...data,
      synonyms: [],
      unit: "time",
    }) as unknown as IssueValue
  );
}
