import { FormControl, FormErrorMessage } from "@chakra-ui/react";
import { FormikErrors } from "formik";
import { map } from "lodash";
import React from "react";

interface Props {
  errors: FormikErrors<unknown>;
}

export default function Errors({ errors }: Props) {
  return (
    <FormControl isInvalid mb={4}>
      {map(errors, (messages, key) => (
        <FormErrorMessage key={key}>
          {typeof messages === "string"
            ? messages
            : (messages as any).join(" ")}
        </FormErrorMessage>
      ))}
    </FormControl>
  );
}
