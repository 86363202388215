import { Button, Flex, Grid, Text } from "@chakra-ui/react";
import { PatternValue, Strategy, TacticValue } from "core";
import { map } from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import Card from "../../../../components/Card/Card";
import CardBody from "../../../../components/Card/CardBody";
import CardHeader from "../../../../components/Card/CardHeader";
import TacticsList from "./TacticsList";

interface Props {
  pattern: PatternValue;
  strategies: { main: Strategy; success: Strategy; setback: Strategy };
  tacticsById: Record<string, TacticValue>;
}

export default function GameplanCard({
  pattern,
  strategies,
  tacticsById,
}: Props) {
  return (
    <Card bg="gray.600" p="16px" mb="24px">
      <CardHeader p="12px 5px" mb="12px">
        <Flex alignItems="center">
          <Text flexGrow={1} fontSize="lg" color="white" fontWeight="bold">
            {pattern.name}
          </Text>
          <Link to={`/recommendations/new/?issueId=${pattern.issueId}`}>
            <Button>+ Add an issue recommendation</Button>
          </Link>
        </Flex>
      </CardHeader>
      <CardBody px="5px">
        <Grid
          templateColumns={{ sm: "1fr", md: "1fr 1fr", xl: "repeat(3, 1fr)" }}
          templateRows={{ sm: "1fr 1fr 1fr auto", md: "1fr 1fr", xl: "1fr" }}
          gap="24px"
        >
          {map(strategies, ({ tacticIds, suggestedTacticIds }, key) => (
            <TacticsList
              tacticIds={tacticIds}
              suggestedTacticIds={suggestedTacticIds || []}
              title={key}
              tacticsById={tacticsById}
            />
          ))}
        </Grid>
      </CardBody>
    </Card>
  );
}
