import { Box, Flex, Text } from "@chakra-ui/react";
import React, { ComponentProps } from "react";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import CardHeader from "../Card/CardHeader";

export type Props = ComponentProps<typeof Flex> & {
  children?: React.ReactNode;
  title: string;
  subtitle?: string;
  controls?: React.ReactNode;
  action?: React.ReactNode;
  titleColor?: string;
  subtitleColor?: string;
};

export default function CardBlock({
  title,
  subtitle,
  children,
  controls,
  action,
  titleColor = "gray.700",
  subtitleColor = "gray.400",
  ...rest
}: Props) {
  return (
    <Card overflowX={{ sm: "scroll", xl: "hidden" }} mb={8} {...rest}>
      <CardHeader p="6px 0px 22px 0px">
        <Flex align="center" justify="space-between" w="100%">
          <Box>
            <Text fontSize="xl" color={titleColor} fontWeight="bold">
              {title}
            </Text>
            {subtitle ? (
              <Text fontSize="md" color={subtitleColor} fontWeight="bold">
                {subtitle}
              </Text>
            ) : null}
          </Box>
          {action}
        </Flex>
      </CardHeader>
      <CardBody>
        {controls}
        {children}
      </CardBody>
    </Card>
  );
}
