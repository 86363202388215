import { TacticValue } from "core";
import {
  addDoc,
  collection,
  CollectionReference,
  Timestamp,
} from "firebase/firestore";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFirestore } from "reactfire";
import { FlashContext } from "../../contexts/FlashProvider";
import TacticForm from "./TacticForm";

export default function NewTacticScreen() {
  const firestore = useFirestore();
  const navigate = useNavigate();
  const { showMessage } = useContext(FlashContext);
  const [isSubmitting, setSubmitting] = useState(false);

  function handleSubmit(values: Partial<TacticValue>) {
    setSubmitting(true);
    addDoc(
      collection(firestore, "tactics") as CollectionReference<TacticValue>,
      {
        ...values,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
      } as TacticValue
    )
      .then(() => {
        showMessage({ type: "success", message: "Tactic saved" });
        navigate("/tactics");
      })
      .finally(() => setSubmitting(false));
  }

  return (
    <TacticForm
      tactic={{ type: "task" }}
      onSubmit={handleSubmit}
      isSubmitting={isSubmitting}
    />
  );
}
