import { Td, Tr } from "@chakra-ui/react";
import { ProfileValue } from "core";
import React from "react";
import { Link } from "react-router-dom";
import { WithId } from "../../types/utils";

interface Props {
  profile: WithId<ProfileValue>;
  isLast?: boolean;
}

export const columnNames = ["Email", "Name", "App version", "Signed up"];

export default function UserRow({ profile, isLast }: Props) {
  const borderColor = "gray.200";

  return (
    <Tr>
      <Td borderColor={borderColor}>
        <Link to={`/users/${profile.id}`}>{profile.id}</Link>
      </Td>
      <Td borderColor={borderColor}>{profile.nickName}</Td>
      <Td borderColor={borderColor}>
        {(profile as any).app?.version || (profile as any).app?.runtimeVersion}
      </Td>
      <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : (null as any)}
      >
        {profile.createdAt?.toDate()?.toDateString()}
      </Td>
      {/* <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : (null as any)}
        pl="0px"
      >
        <Button p="0px" bg="transparent" variant="no-effects">
          Edit
        </Button>
      </Td> */}
    </Tr>
  );
}
