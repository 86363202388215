import { Flex, Text } from "@chakra-ui/react";
import { ProfileValue } from "core";
import React from "react";

interface Props {
  profile: ProfileValue;
}

const bgProfile = "hsla(0,0%,100%,.8)";
const borderProfileColor = "white";
const textColor = "gray.700";

export default function ProfileHeader({ profile }: Props) {
  return (
    <Flex
      direction={{ sm: "column", md: "row" }}
      mb="24px"
      maxH="330px"
      justifyContent={{ sm: "center", md: "space-between" }}
      align="center"
      backdropFilter="blur(21px)"
      boxShadow="0px 2px 5.5px rgba(0, 0, 0, 0.02)"
      border="1.5px solid"
      borderColor={borderProfileColor}
      bg={bgProfile}
      p="24px"
      borderRadius="20px"
    >
      <Flex
        align="center"
        mb={{ sm: "10px", md: "0px" }}
        direction={{ sm: "column", md: "row" }}
        w={{ sm: "100%" }}
        textAlign={{ sm: "center", md: "start" }}
      >
        <Flex direction="column" maxWidth="100%" my={{ sm: "14px" }}>
          <Text
            fontSize={{ sm: "lg", lg: "xl" }}
            color={textColor}
            fontWeight="bold"
            ms={{ sm: "8px", md: "0px" }}
            data-testid="user-display-name"
          >
            {profile.nickName}
          </Text>
          {/* <Text
            fontSize={{ sm: "sm", md: "md" }}
            color={emailColor}
            fontWeight="semibold"
          >
            {profile.email}
          </Text> */}
        </Flex>
      </Flex>
    </Flex>
  );
}
