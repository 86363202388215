import { Flex, Text } from "@chakra-ui/react";
import { GameplanValue, PatternValue, ProfileValue } from "core";
import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  query,
  where,
} from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import {
  useFirestore,
  useFirestoreCollection,
  useFirestoreDocData,
} from "reactfire";
import PatternCard from "./components/PatternCard";
import ProfileHeader from "./components/ProfileHeader";

export default function UserScreen() {
  const { id } = useParams();
  const firestore = useFirestore();
  const { data: profile, status: profileStatus } = useFirestoreDocData(
    doc(firestore, "profiles", id!) as DocumentReference<ProfileValue>
  );
  const { data: gameplan, status: gameplanStatus } = useFirestoreDocData(
    doc(firestore, "gameplans", id!) as DocumentReference<GameplanValue>
  );
  const {
    data: { docs: patterns = [] },
  } = useFirestoreCollection<PatternValue>(
    query(
      collection(firestore, "patterns") as CollectionReference<PatternValue>,
      where("uid", "==", id!)
    )
  );

  if (profileStatus === "loading" || gameplanStatus === "loading")
    return <Text>"..."</Text>;
  if (!profile || !gameplan) return <Text>Not found</Text>;

  return (
    <Flex direction="column">
      <ProfileHeader profile={profile} />
      {patterns.map((pattern) => (
        <PatternCard
          pattern={pattern.data()}
          strategies={{
            main: gameplan.main[pattern.id],
            success: gameplan.success?.[pattern.id] || {},
            setback: gameplan.setback?.[pattern.id] || {},
          }}
          tacticsById={gameplan.tacticsById}
        />
      ))}
    </Flex>
  );
}
