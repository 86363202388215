import { ProgramValue } from "core";
import { createContext } from "react";
import { Snap } from "../../utils/firestore";

interface ProgramsContextValue {
  programs: Array<Snap<ProgramValue>>;
}
export const ProgramsContext = createContext<ProgramsContextValue>({
  programs: [],
});
