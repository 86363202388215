import { getParents, NodeModel } from "@minoru/react-dnd-treeview";
import { IssueValue } from "core";
import {
  collection,
  CollectionReference,
  doc,
  query,
  writeBatch,
} from "firebase/firestore";
import React, { Dispatch, useEffect, useMemo } from "react";
import { useFirestore, useFirestoreCollection } from "reactfire";
import useChangeEffect from "../../../../../utils/useChangeEffect";
import { getTreeData, TreeData } from "./getTreeData";

export function syncTreeData(
  treeData: Array<NodeModel>,
  setTreeData: Dispatch<React.SetStateAction<TreeData>>
) {
  const firestore = useFirestore();
  const issuesCollection = collection(
    firestore,
    "issues"
  ) as CollectionReference<IssueValue>;
  const { data = { docs: [] } } = useFirestoreCollection(
    query(issuesCollection)
  );
  const derivedTreeData = useMemo(() => getTreeData(data.docs), [data]);

  useEffect(() => {
    setTreeData(derivedTreeData);
  }, [JSON.stringify(derivedTreeData)]);

  useChangeEffect(() => {
    const batch = writeBatch(firestore);

    console.log("change to issue data");

    treeData.forEach((node) => {
      const ref = doc(issuesCollection, node.id.toString());
      const parents = getParents(treeData, node.id)
        .map(({ id, text }) => ({
          id,
          text,
        }))
        .reverse();
      const parentIds = [
        ...parents.map(({ id }) => id as string),
        node.id as string,
      ];
      const parentNames = [...parents.map(({ text }) => text), node.text];
      const path = parentNames.join(" > ");

      batch.set(
        ref,
        {
          name: node.text,
          parentId: node.parent.toString() || "0",
          isFeatured: !!(node.data as Partial<IssueValue>)?.isFeatured,
          parentIds,
          path,
          parentNames,
        },
        { merge: true }
      );
    });
    batch.commit();
  }, [JSON.stringify(treeData)]);

  return treeData;
}
