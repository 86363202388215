import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { Box, Flex, IconButton } from "@chakra-ui/react";
import { TacticValue } from "core";
import React from "react";

interface Props {
  tactic: TacticValue;
  onEditButtonClick?: () => void;
  onRemoveButtonClick?: () => void;
}

export default function Tactic({
  tactic,
  onEditButtonClick,
  onRemoveButtonClick,
}: Props) {
  return (
    <Flex
      data-testid="tactic-card"
      alignItems="center"
      flexDirection="row"
      mb={4}
      p={2}
      bg="gray.100"
      borderRadius={12}
    >
      <Box flexGrow={1}>{tactic.title}</Box>
      {onEditButtonClick ? (
        <IconButton
          onClick={onEditButtonClick}
          aria-label="Edit this tactic"
          icon={<EditIcon />}
          size="sm"
          data-testid="edit-tactic-button"
        />
      ) : null}
      {onRemoveButtonClick ? (
        <IconButton
          onClick={onRemoveButtonClick}
          aria-label="Remove this tactic"
          icon={<DeleteIcon />}
          size="sm"
          data-testid="remove-tactic-button"
        />
      ) : null}
    </Flex>
  );
}
