import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import React, { ComponentProps } from "react";
import Select from "react-select";
import FormSection from "../../../../components/FormSection";

type Props = Partial<ComponentProps<typeof FormSection>> & {
  title: string;
  tacticOptions: Array<{ label: string; value: string }>; // All available tactics to choose from
  tacticIds: Array<string>; // The tacticIds for this section of the gameplan
  errors?: Record<string, string[]>;
  onChange: (tacticIds: Array<string>) => void;
};

// You should be able to add tactics to the strategy and see them in the list
export function StrategySection({
  tacticOptions,
  tacticIds,
  title,
  errors,
  onChange,
  ...rest
}: Props) {
  return (
    <FormControl isInvalid={!!errors?.tacticIds} {...rest}>
      <FormLabel>{title}</FormLabel>
      <Select
        isMulti
        value={tacticOptions.filter(({ value }) => tacticIds?.includes(value))}
        inputId="program-tactics-select"
        onChange={(selections) =>
          onChange(selections.map(({ value }) => value))
        }
        options={tacticOptions}
      ></Select>
      <FormErrorMessage>{errors?.tacticIds}</FormErrorMessage>
    </FormControl>
  );
}
