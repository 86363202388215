import { browserLocalPersistence, getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import React from "react";
import { RouterProvider } from "react-router-dom";
import {
  AuthProvider,
  FirestoreProvider,
  FunctionsProvider,
  StorageProvider,
  useFirebaseApp,
} from "reactfire";
import WithFlashProvider from "./contexts/FlashProvider";
import router from "./router";

import { getStorage } from "firebase/storage";
import "rc-time-picker/assets/index.css";
import "./App.css";

export default function App() {
  const app = useFirebaseApp();
  const firestoreInstance = getFirestore(app);
  const auth = getAuth(app);
  const functions = getFunctions(app);
  const storage = getStorage(app);

  auth.setPersistence(browserLocalPersistence);

  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <AuthProvider sdk={auth}>
        <FunctionsProvider sdk={functions}>
          <StorageProvider sdk={storage}>
            <WithFlashProvider>
              <RouterProvider router={router} />
            </WithFlashProvider>
          </StorageProvider>
        </FunctionsProvider>
      </AuthProvider>
    </FirestoreProvider>
  );
}
