import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const dashboardUrl =
  "https://redash.impulse.training/public/dashboards/yTCkJXj6aTRG23v5rXlBa7tCvD7Ym9xeoo6hGxoO?org_slug=default";

export default function Reports() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/issues");
  }, []);

  return <div>Redirecting...</div>;
  return (
    <iframe
      style={{
        width: "100%",
        height: "100%",
      }}
      src={dashboardUrl}
    />
  );
}
