const firebaseConfig = {
  apiKey: "AIzaSyAwLVnnXMq6L6VLboLigB3bQGe-JKGNA0M",
  authDomain: "impulse-tmp.firebaseapp.com",
  projectId: "impulse-tmp",
  storageBucket: "impulse-tmp.appspot.com",
  messagingSenderId: "199765336595",
  appId: "1:199765336595:web:ca3f6f332e309041a055e6",
  measurementId: "G-0VJBQVWH09",
};

export default firebaseConfig;
