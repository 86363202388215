import { Td, Tr } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { WithId } from "../../types/utils";

interface Props {
  doc: WithId<unknown>;
  linkTo: string;
  errors: Array<any>;
}

export const columnNames = ["ID", "Errors"];

export default function DocumentRow({ doc, linkTo, errors }: Props) {
  const borderColor = "gray.200";

  return (
    <Tr>
      <Td borderColor={borderColor}>
        <Link to={linkTo}>{doc.id}</Link>
      </Td>
      <Td borderColor={borderColor}>
        <pre>
          <code>
            {JSON.stringify(
              errors.map((error) => error.message),
              null,
              2
            )}
          </code>
        </pre>
      </Td>
    </Tr>
  );
}
