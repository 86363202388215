import { RepeatIcon } from "@chakra-ui/icons";
import { Button, IconButton, Td, Tr } from "@chakra-ui/react";
import { TacticValue } from "core";
import { deleteDoc, updateDoc } from "firebase/firestore";
import React from "react";
import { Link } from "react-router-dom";
import { Snap } from "../../utils/firestore";

interface Props {
  tactic: Snap<TacticValue>;
}

export const columnNames = ["Title", "Type", "Recommendable", ""];

export default function TacticRow({ tactic }: Props) {
  const borderColor = "gray.200";
  const data = tactic.data();

  function handleDeleteButtonClick() {
    if (confirm("Are you sure you want to delete this tactic?"))
      deleteDoc(tactic.ref);
  }

  function toggleRecommendable() {
    updateDoc(tactic.ref, {
      isAvailableForRecommendation: !data.isAvailableForRecommendation,
    });
  }

  return (
    <Tr>
      <Td borderColor={borderColor}>{data.title}</Td>
      <Td borderColor={borderColor}>{data.type}</Td>
      <Td borderColor={borderColor}>
        {data.isAvailableForRecommendation ? "Yes" : "No"}
        <IconButton
          aria-label="Toggle recommendable"
          icon={<RepeatIcon />}
          onClick={toggleRecommendable}
        ></IconButton>
      </Td>
      <Td borderColor={borderColor}>
        <Link to={`/tactics/${tactic.id}/edit`}>
          <Button mr={2}>Edit</Button>
        </Link>

        <Button onClick={handleDeleteButtonClick}>Delete</Button>
      </Td>
    </Tr>
  );
}
