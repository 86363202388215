import { Button, Flex } from "@chakra-ui/react";
import { IssueValue, sortPrograms } from "core";
import { collection, CollectionReference, doc } from "firebase/firestore";
import React, { useContext, useEffect, useMemo } from "react";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useFirestore, useFirestoreDoc } from "reactfire";
import CardBlock from "../../../../components/CardBlock";
import { columnNames as programColumnNames } from "../../components/ProgramRow";
import { ProgramsContext } from "../../ProgramsContext";

// This is the component that renders the issue and it's programs on the issues index page
export default function IssuePage() {
  const { issueId, programId } = useParams();
  let location = useLocation();
  const isNewPage = location.pathname.endsWith("new");
  const firestore = useFirestore();
  const navigate = useNavigate();
  const issuesCollection = collection(
    firestore,
    "issues"
  ) as CollectionReference<IssueValue>;
  const issueDoc = useMemo(() => doc(issuesCollection, issueId), [issueId]);
  const { data: issue } = useFirestoreDoc<IssueValue>(issueDoc);
  const { programs } = useContext(ProgramsContext);
  const parentIssueIds = issue?.data()?.parentIds || [];
  const programsArray = programs.map((doc) => ({
    id: doc.id,
    _data: doc.data(),
  }));
  const sortedPrograms = useMemo(
    () => sortPrograms(programsArray, parentIssueIds),
    [programs, parentIssueIds]
  );
  const bestProgram = sortedPrograms[0];
  const showAddProgramButton =
    !isNewPage && !bestProgram?._data?.forIssueIds?.includes(issueId!);
  const subtitle =
    (isNewPage ? "Create a new program for " : "Best program: ") +
    bestProgram?._data?.title;

  useEffect(() => {
    if (!isNewPage && !programId && bestProgram) {
      navigate(`/issues/${issueId}/programs/${bestProgram.id}`);
    }
  }, [programId, isNewPage, bestProgram?.id]);

  if (!issue) return null;
  const issueData = issue.data()!;

  return (
    <CardBlock
      title={issueData.name}
      subtitle={subtitle}
      mb={0}
      columnNames={programColumnNames}
      bg="gray.200"
      selectedProgram
      action={
        <Flex>
          {showAddProgramButton ? (
            <Link to={`/issues/${issueId}/programs/new`}>
              <Button mr={2} colorScheme={"purple"}>
                + Add a program for {issueData.name}
              </Button>
            </Link>
          ) : null}
          {isNewPage ? (
            <Link to={`/issues/${issueId}`}>
              <Button>Cancel</Button>
            </Link>
          ) : (
            <Button variant="ghost">View issue details →</Button>
          )}
        </Flex>
      }
    >
      <Outlet />
    </CardBlock>
  );
}
