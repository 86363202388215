import { Box } from "@chakra-ui/react";
// import { MailValue } from "core";
import React from "react";
import { Outlet, useParams } from "react-router-dom";
import { useFirestore } from "reactfire";

// The index component for mail is just an outlet that renders the child route.
export function Mails() {
  return <Outlet />;
}

export function Mail() {
  const { id } = useParams();
  const firestore = useFirestore();
  // const { data } = useFirestoreDocDataOnce(
  //   doc(
  //     collection(firestore, "mail") as CollectionReference<
  //       WithId<MailValue<any>>
  //     >,
  //     id
  //   )
  // );

  return (
    <Box>
      {/* <iframe style={{ width: "100%", height: "90vh" }} srcDoc={data?.html} /> */}
    </Box>
  );
}
