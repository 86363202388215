import { ImageType } from "core";
import React from "react";
import { useImageUri } from "../../utils/images";

type Props = React.ImgHTMLAttributes<HTMLImageElement> & {
  image?: ImageType | null;
};
export default function Image({ image, ...rest }: Props) {
  const uri = useImageUri(image);
  console.log({ uri });
  const grayBoxSvg = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Crect width='100' height='100' fill='%23ccc'/%3E%3C/svg%3E`;
  const displayUri = uri || grayBoxSvg;

  return <img src={displayUri} {...rest} />;
}
