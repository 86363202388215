import { useEffect, useRef } from "react";

export default function useChangeEffect(func: Function, deps: Array<unknown>) {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
}
