import { TacticValue } from "core";
import { doc, DocumentReference, updateDoc } from "firebase/firestore";
import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { FlashContext } from "../../contexts/FlashProvider";
import TacticForm from "./TacticForm";

export default function EditTacticScreen() {
  const { id } = useParams();
  const firestore = useFirestore();
  const tacticDoc = doc(firestore, `tactics/${id}`);
  const [isSubmitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const { data, status } = useFirestoreDocData(
    tacticDoc as DocumentReference<TacticValue>
  );
  const { showMessage } = useContext(FlashContext);

  function handleSubmit(values: Partial<TacticValue>) {
    setSubmitting(true);
    updateDoc(tacticDoc, values).finally(() => setSubmitting(false));
    showMessage({ message: "Tactic updated", type: "success" });
    navigate("/tactics");
  }

  if (status === "loading") return <div>Loading...</div>;

  return (
    <TacticForm
      tactic={data}
      isSubmitting={isSubmitting}
      onSubmit={handleSubmit}
    />
  );
}
